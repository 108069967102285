import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import cx from 'classnames';

import { AuthContext } from '@/components/AuthContext';
import { StyledButton } from '@/components/UI/Button';
import { logout } from '@/services/auth';
import ArrowBack from '@/assets/icons/arrow-back.svg';
import Logo from '@/assets/icons/logo.svg';

import { DASHBOARD_ROUTE } from '../../consts';
import styles from './styles.module.scss';
import axios from 'axios';

const Header = ({ isIndexPage, isLoginPage, homePageTitle, currentPageTitle, onLoginClick }) => {
    const [isLoggedIn, setIsLoggedIn] = useContext(AuthContext);
    const [role, SetRole] = useState([]);

    useEffect(() => {
        getRole();
    }, []);

    const handleLogout = useCallback(async (e) => {
        e.preventDefault();
        await logout();
        setIsLoggedIn(false);
    });

    const getRole = async () => {
        await axios
            .get('/bigdata-back/api/role', {
                //.get('https://bigdata.demo.megafon.ru/bigdata-back/api/role', {
                data: {}
            })
            .then((result) => {
                const {
                    data: { role = {} }
                } = result;
                SetRole(role);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <header
            className={cx(styles.root, isIndexPage ? styles.isIndexPage : '', isLoginPage ? styles.isLoginPage : '')}>
            {!isIndexPage && !isLoginPage && (
                <div className={styles.breadcrumbs}>
                    <Link className={styles.home} to={DASHBOARD_ROUTE}>
                        <ArrowBack className={styles.homeIcon} />
                        {homePageTitle.toLowerCase()}
                    </Link>
                    {currentPageTitle && (
                        <span className={styles.currentPage}>&nbsp;/&nbsp;{currentPageTitle.toLowerCase()}</span>
                    )}
                </div>
            )}

            {(isIndexPage || isLoginPage) && (
                <div className={styles.logo}>
                    <Logo className={cx(styles.logo, isLoginPage ? styles.logoWhite : '')} />
                </div>
            )}

            <div className={styles.buttons}>
                {!role && (
                    <Link className={styles.addLink} to={'app/add-phone'}>
                        Добавить пользователя
                    </Link>
                )}
                {isLoggedIn ? (
                    <StyledButton noBorder md onClick={handleLogout}>
                        Выйти
                    </StyledButton>
                ) : (
                    <StyledButton className={styles.loginBtn} primary md onClick={onLoginClick}>
                        Войти
                    </StyledButton>
                )}
            </div>
        </header>
    );
};

Header.propTypes = {
    currentPageTitle: PropTypes.string,
    homePageTitle: PropTypes.string
};

Header.defaultProps = {
    homePageTitle: 'главная'
};

export default Header;
