export const NOT_FOUND_STATUS = 'NOT_FOUND';
export const SUCCESS_STATUS = 'SUCCESS';
export const CREDIT_SCORE_NAME = 'CREDIT_SCORE';
export const COVID_SCORE_NAME = 'COVID_MODEL_20200405';

export const COVID_VARIABLES = [
    { name: 'AUD1_COME_BACK_FROM_ROAM_GROUP_PHONES_COUNT_D28' },
    { name: 'AUD2_COME_BACK_FROM_DANGER_GROUP_PHONES_COUNT_D28' },
    { name: 'AUD3_COME_BACK_AND_SICK_GROUP_PHONES_COUNT_D28' },
    { name: 'AUD4_INFECTED_GROUP_PHONES_COUNT_D28' },
    { name: 'AUD5_RETURN_FROM_MSK_MO_GROUP_PHONES_COUNT_D28' },
    { name: 'COVID_MODEL_20200405' }
];

export const COVID_ATTRIBUTES = [
    {
        name: 'ADDRESSES',
        value: [
            {
                type: 'home',
                region: 'Московская область',
                citytype: 'г',
                city: 'Долгопрудный',
                streettype: 'ул',
                street: 'Первомайская',
                house: '1'
            },
            {
                type: 'work',
                region: 'Москва',
                citytype: 'г',
                city: 'Москва',
                streettype: 'ул',
                street: 'Большая Ордынка',
                house: '19',
                stroenie: '2'
            }
        ]
    }
];

export const COVID_VARIABLE_INFO = {
    AUD1_COME_BACK_FROM_ROAM_GROUP_PHONES_COUNT_D28: {
        alias: 'Проверить наличие контактов с людьми, которые должны находиться на COVID-карантине',
        legend: {
            '0': {
                text: 'не найдено',
                backgroundColor: '#10E272'
            },
            '1': {
                text: 'были',
                backgroundColor: '#EB5A40'
            }
        }
    },
    AUD2_COME_BACK_FROM_DANGER_GROUP_PHONES_COUNT_D28: {
        alias: 'Проверить наличие контактов с людьми, которые вернулись из стран с критической ситуацией в части COVID',
        legend: {
            '0': {
                text: 'не найдено',
                backgroundColor: '#10E272'
            },
            '1': {
                text: 'были',
                backgroundColor: '#EB5A40'
            }
        }
    },
    AUD3_COME_BACK_AND_SICK_GROUP_PHONES_COUNT_D28: {
        alias:
            'Проверить наличие контактов с людьми, которые вернулись из стран с критической ситуацией в части COVID, и заболели ',
        legend: {
            '0': {
                text: 'не найдено',
                backgroundColor: '#10E272'
            },
            '1': {
                text: 'были',
                backgroundColor: '#EB5A40'
            }
        }
    },
    AUD4_INFECTED_GROUP_PHONES_COUNT_D28: {
        alias: 'Проверить наличие контактов с инфицированными COVID-19 людьми',
        legend: {
            '0': {
                text: 'не найдено',
                backgroundColor: '#10E272'
            },
            '1': {
                text: 'были',
                backgroundColor: '#EB5A40'
            }
        }
    },
    AUD5_RETURN_FROM_MSK_MO_GROUP_PHONES_COUNT_D28: {
        alias: 'Проверить наличие контактов с людьми, которые вернулись из Московского региона',
        legend: {
            '0': {
                text: 'не найдено',
                backgroundColor: '#10E272'
            },
            '1': {
                text: 'были',
                backgroundColor: '#EB5A40'
            }
        }
    }
};

export const SCORING_VARIABLES = [
    { name: 'CREDIT_SCORE' },
    { name: 'ALL_CLC' },
    { name: 'BLOCK_CNT' },
    { name: 'BLOCK_DUR' },
    { name: 'CIRCLE' },
    { name: 'FIO_CORRECTNESS' },
    { name: 'IS_HOME' },
    { name: 'IS_WORK' },
    { name: 'LIFETIME_BIN' },
    { name: 'PAY_MAX' },
    { name: 'INTEGRAL_SCOR' }
];

export const SCORING_ATTRIBUTES = [
    {
        name: 'ADDRESSES',
        value: [
            {
                type: 'home',
                region: 'Московская область',
                citytype: 'г',
                city: 'Долгопрудный',
                streettype: 'ул',
                street: 'Первомайская',
                house: '1'
            },
            {
                type: 'work',
                region: 'Москва',
                citytype: 'г',
                city: 'Москва',
                streettype: 'ул',
                street: 'Большая Ордынка',
                house: '19',
                stroenie: '2'
            }
        ]
    }
];

export const SCORING_VARIABLE_INFO = {
    CREDIT_SCORE: {
        alias: 'Кредитный скоринг физического лица (вероятность просрочки кредита на горизонте 12 месяцев)'
    },
    RISK_REQUESTS_SCORE: {
        alias: 'Интерес к кредитованию за 28 дней'
    },
    FAKE_MSISDN_SCORE: { alias: 'Похожесть на мошенника (антифрод)' },
    OUT_GRACE_SCORE: {
        alias: 'Предсказания выхода за рамки GRACE периода по кредитной карте'
    },
    ALL_CLC: {
        order: 1,
        altOrder: 1,
        unit: 'руб/мес',
        alias: 'Расходы на услуги связи',
        description: 'начисления по абонентскому номеру',
        legend: {
            '0': {
                text: '< 100',
                backgroundColor: '#F62434'
            },
            '1': {
                text: '100 - 300',
                backgroundColor: '#EB5A40'
            },
            '2': {
                text: '300 - 500',
                backgroundColor: '#FFA717'
            },
            '3': {
                text: '500 - 1000',
                backgroundColor: 'yellow'
            },
            '4': {
                text: '1000 - 3000',
                backgroundColor: '#10E272'
            },
            '5': {
                text: '> 3000',
                backgroundColor: '#00B956'
            }
        }
    },
    BLOCK_CNT: {
        order: 3,
        altOrder: 2,
        alias: 'Количество блокировок абонентского номера',
        description: 'Количество блокировок абонентского номера (среднемесячное значение за период)',
        legend: {
            '0': {
                text: '< 1',
                backgroundColor: '#00B956'
            },
            '1': {
                text: '< 2',
                backgroundColor: '#10E272'
            },
            '2': {
                text: '2 - 4',
                backgroundColor: 'yellow'
            },
            '3': {
                text: '5 - 9',
                backgroundColor: '#FFA717'
            },
            '4': {
                text: '10 - 15',
                backgroundColor: '#EB5A40'
            },
            '5': {
                text: '> 15',
                backgroundColor: '#F62434'
            }
        }
    },
    BLOCK_DUR: {
        altOrder: 3,
        order: 5,
        alias: 'Длительности блокировок абонентского номера',
        description: 'длительность блокировок абонентского номера',
        legend: {
            '0': {
                text: 'менее 1 часа',
                backgroundColor: '#00B956'
            },
            '1': {
                text: 'от 1 часа до 24 часов',
                backgroundColor: '#10E272'
            },
            '2': {
                text: 'от 1 до 2 суток',
                backgroundColor: 'yellow'
            },
            '3': {
                text: 'от 2 до 3 суток',
                backgroundColor: '#FFA717'
            },
            '4': {
                text: 'от 3 до 15 суток',
                backgroundColor: '#EB5A40'
            },
            '5': {
                text: '> 15 суток',
                backgroundColor: '#F62434'
            }
        }
    },
    CIRCLE: {
        altOrder: 4,
        order: 7,
        alias: 'Круг общения',
        description:
            'количество абонентских номеров, с которыми у абонентского номера Клиента были транзакции (среднемесячное значение за период)',
        legend: {
            '0': {
                text: 'менее 5',
                backgroundColor: '#F62434'
            },
            '1': {
                text: 'от 5 до 14',
                backgroundColor: '#EB5A40'
            },
            '2': {
                text: 'от 15 до 24',
                backgroundColor: '#FFA717'
            },
            '3': {
                text: 'от 25 до 39',
                backgroundColor: 'yellow'
            },
            '4': {
                text: 'от 40 до 50',
                backgroundColor: '#10E272'
            },
            '5': {
                text: 'более 50',
                backgroundColor: '#00B956'
            }
        }
    },
    FIO_CORRECTNESS: {
        alias: 'Подтверждение ФИО Клиента',
        description: 'Подтверждение ФИО Клиента',
        legend: {
            '0': {
                text: 'не совпадают',
                backgroundColor: '#F62434'
            },
            '1': {
                text: 'совпадают',
                backgroundColor: '#00B956'
            },
            '2': {
                text: 'не определены',
                backgroundColor: 'grey'
            }
        }
    },
    IS_HOME: {
        altOrder: 5,
        order: 2,
        alias: 'Нагрузка Абонентского устройства на базовые станции в ночное время',
        description: 'Абонентское устройство Клиента в ночное время создавало нагрузку на базовые станции в районе:',
        legend: {
            '0': {
                altText: 'совпадающим с адресом, указанным Заказчиком',
                backgroundColor: '#F62434'
            },
            '1': {
                altText: 'отличном от адреса, указанного Заказчиком',
                backgroundColor: '#00B956'
            },
            '2': {
                altText: 'информация отсутствует',
                backgroundColor: 'grey'
            }
        }
    },
    IS_WORK: {
        altOrder: 6,
        order: 4,
        alias: 'Нагрузка Абонентского устройства на базовые станции в дневное время',
        description: 'Абонентское устройство Клиента в дневное время создавало нагрузку на базовые станции в районе:',
        legend: {
            '0': {
                altText: 'совпадающим с адресом, указанным Заказчиком',
                backgroundColor: '#F62434'
            },
            '1': {
                altText: 'отличном от адреса, указанного Заказчиком',
                backgroundColor: '#00B956'
            },
            '2': {
                altText: 'информация отсутствует',
                backgroundColor: 'grey'
            }
        }
    },
    LIFETIME_BIN: {
        altOrder: 7,
        order: 6,
        alias: 'Срок жизни абонентского номера',
        description: 'С даты активации абонентского номера прошло',
        legend: {
            '0': {
                text: 'менее 30 дней',
                backgroundColor: '#F62434'
            },
            '1': {
                text: 'от 1 до 3 месяцев',
                backgroundColor: '#EB5A40'
            },
            '2': {
                text: 'от 3 до 6 месяцев',
                backgroundColor: '#FFA717'
            },
            '3': {
                text: 'от 6 до 12 месяцев',
                backgroundColor: 'yellow'
            },
            '4': {
                text: 'от 1 года до 3 лет',
                backgroundColor: '#10E272'
            },
            '5': {
                text: 'более 3 лет',
                backgroundColor: '#00B956'
            }
        }
    },
    PAY_MAX: {
        unit: 'руб',
        altOrder: 8,
        order: 8,
        alias: 'Размер максимального платежа',
        description: 'максимальный платеж по абонентскому номеру',
        legend: {
            '0': {
                text: '< 20',
                backgroundColor: '#F62434'
            },
            '1': {
                text: '20 - 100',
                backgroundColor: '#EB5A40'
            },
            '2': {
                text: '100 - 200',
                backgroundColor: '#FFA717'
            },
            '3': {
                text: '200 - 500',
                backgroundColor: 'yellow'
            },
            '4': {
                text: '500 - 1000',
                backgroundColor: '#10E272'
            },
            '5': {
                text: '> 1000',
                backgroundColor: '#00B956'
            }
        }
    },
    INTEGRAL_SCOR: { alias: 'Общий рекомендательный балл' }
};

export const HEATMAP_STYLES = [
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e9e9e9'
            },
            {
                lightness: 17
            }
        ]
    },
    {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5'
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                lightness: 17
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                lightness: 29
            },
            {
                weight: 0.2
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                lightness: 18
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff'
            },
            {
                lightness: 16
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5'
            },
            {
                lightness: 21
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dedede'
            },
            {
                lightness: 21
            }
        ]
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'on'
            },
            {
                color: '#ffffff'
            },
            {
                lightness: 16
            }
        ]
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                saturation: 36
            },
            {
                color: '#333333'
            },
            {
                lightness: 40
            }
        ]
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        featureType: 'transit',
        elementType: 'geometry',
        stylers: [
            {
                color: '#f2f2f2'
            },
            {
                lightness: 19
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#fefefe'
            },
            {
                lightness: 20
            }
        ]
    },
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#fefefe'
            },
            {
                lightness: 17
            },
            {
                weight: 1.2
            }
        ]
    }
];

export const HEATMAP_GRADIENTS = [
    'rgba(255, 201, 82, 0)',
    'rgba(255, 201, 82, 1)',
    'rgba(255, 201, 82, 1)',
    'rgba(255, 201, 82, 1)',
    'rgba(255, 201, 82, 1)',
    'rgba(255, 201, 82, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 139, 15, 1)',
    'rgba(255, 95, 0, 1)',
    'rgba(255, 95, 0, 1)',
    'rgba(255, 95, 0, 1)',
    'rgba(255, 95, 0, 1)',
    'rgba(255, 95, 0, 1)',
    'rgba(255, 95, 0, 1)',
    'rgba(255, 95, 0, 1)',
    'rgba(255, 0, 0, 1)'
];

export const HEATMAP_MAX_ZOOM = 14;

export const DASHBOARD_ROUTE = '/app';
export const INDEX_ROUTE = '/';

export const colors = {
    green: '#00b956',
    orange: '#ffa717',
    deepOrange: '#f36f20',
    red: '#eb5a40'
};

export const riskThreshold = {
    middle: 0.016073,
    high: 0.041829,
    danger: 0.046933
};

export const riskThresholdCOVID = {
    danger: 0.15
};
