const axios = require('axios');

const validateStatus = (status) => status >= 200 && status < 500;
const public_url = '/bigdata-back';

// For developers: use it on local
//const public_url = 'https://bigdata.demo.megafon.ru/bigdata-back';
//const public_url = 'http://localhost:8000/bigdata-back';

export const checkIfLoggedIn = () => axios.post(public_url + '/api/check', {}, { validateStatus });
export const login = (data) => axios.post(public_url + '/api/authmoblie', data, { validateStatus });
export const altLogin = (data) => axios.post(public_url + '/api/login', data, { validateStatus });
export const logout = () => axios.post(public_url + '/api/logout', {}, { validateStatus });
