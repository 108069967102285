import React, { Component } from 'react';
import { Transition } from 'react-transition-group';

const addClass = (el, className) => {
  el.className += ` ${className}`;
};

const removeClass = (el, className) => {
  el.className = el.className
    .split(' ')
    .filter(v => v !== className)
    .join(' ');
};

const TRANSITION_CSS_CLASS = 'collapse-transition';

export default class CollapseTransition extends Component {
  constructor(props) {
    super(props);

    this.onEnter = this.onEnter.bind(this);
    this.onEntering = this.onEntering.bind(this);
    this.onEntered = this.onEntered.bind(this);
  }

  onEnter(node) {
    // console.log('onEnter');
    addClass(node, TRANSITION_CSS_CLASS);

    if (!node.dataset) node.dataset = {};

    node.dataset.oldPaddingTop = node.style.paddingTop;
    node.dataset.oldPaddingBottom = node.style.paddingBottom;
    node.dataset.oldOverflow = node.style.overflow;

    node.style.height = '0';
    node.style.paddingTop = 0;
    node.style.paddingBottom = 0;
  }

  onEntering(node) {
    if (node.scrollHeight !== 0) {
      node.style.height = node.scrollHeight + 'px';
      node.style.paddingTop = node.dataset.oldPaddingTop;
      node.style.paddingBottom = node.dataset.oldPaddingBottom;
    } else {
      node.style.height = '';
      node.style.paddingTop = node.dataset.oldPaddingTop;
      node.style.paddingBottom = node.dataset.oldPaddingBottom;
    }

    node.style.overflow = 'hidden';
  }

  onEntered(node) {
    // for safari: remove class then reset height is necessary
    removeClass(node, TRANSITION_CSS_CLASS);
    node.style.height = '';
    node.style.overflow = node.dataset.oldOverflow;
  }

  onExit(node) {
    if (!node.dataset) node.dataset = {};
    node.dataset.oldPaddingTop = node.style.paddingTop;
    node.dataset.oldPaddingBottom = node.style.paddingBottom;
    node.dataset.oldOverflow = node.style.overflow;

    node.style.height = node.scrollHeight + 'px';
    node.style.overflow = 'hidden';
    // console.log('lalalla');
  }

  onExiting(node, done) {
    if (node.scrollHeight !== 0) {
      // for safari: add class after set height, or it will jump to zero height suddenly, weired
      addClass(node, TRANSITION_CSS_CLASS);
      node.style.height = 0;
      node.style.paddingTop = 0;
      node.style.paddingBottom = 0;
    }
    setTimeout(done, 10000);
  }

  onExited(node) {
    removeClass(node, TRANSITION_CSS_CLASS);
    node.style.height = '';
    node.style.overflow = node.dataset.oldOverflow;
    node.style.paddingTop = node.dataset.oldPaddingTop;
    node.style.paddingBottom = node.dataset.oldPaddingBottom;
  }

  render() {
    return (
      <Transition
        in={this.props.in}
        timeout={{ enter: 200, exit: 200 }}
        onEnter={this.onEnter}
        onEntering={this.onEntering}
        onEntered={this.onEntered}
        onExit={this.onExit}
        onExiting={this.onExiting}
        onExited={this.onExited}
        unmountOnExit
      >
        {this.props.children}
      </Transition>
    );
  }
}
