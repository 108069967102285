import React, { useState, useEffect } from 'react';
import { checkIfLoggedIn } from '../../services/auth';

const AuthContext = React.createContext([false, () => {}]);

const AuthContextProvider = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [hasCheckedLogin, setHasCheckedLogin] = useState(false);
    useEffect(() => {
        const updateLoggedIn = async () => {
            const response = await checkIfLoggedIn();
            setIsLoggedIn(response.status === 200);
            setHasCheckedLogin(true);
        };
        updateLoggedIn();
    }, []);
    return hasCheckedLogin ? (
        <AuthContext.Provider value={[isLoggedIn, setIsLoggedIn]}>{props.children}</AuthContext.Provider>
    ) : null;
};

export { AuthContext, AuthContextProvider };
