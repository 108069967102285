import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles.module.scss';

export const Button = ({ children, className, onClick, ...rest }) => {
  return (
    <button className={cx(styles.root, className)} onClick={onClick} {...rest}>
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
};

export const StyledButton = ({ children, primary, md, noBorder, className, onClick, ...rest }) => {
  return (
    <button
      className={cx(
        styles.button,
        primary ? styles.primary : '',
        md ? styles.medium : '',
        noBorder ? styles.noBorder : '',
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

StyledButton.propTypes = {
  onClick: PropTypes.func,
};

export const StyledButtonSmall = ({ children, primary, className, onClick, ...rest }) => {
  return (
    <button
      className={cx(styles.buttonSmall, primary ? styles.primary : '', className)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

StyledButton.propTypes = {
  onClick: PropTypes.func,
};
